@import 'mapbox-gl/dist/mapbox-gl.css';
@import 'animate.css';
@import 'react-slideshow-image/dist/styles.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:ital,wght@0,300;0,700;1,300&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.map {
    @apply w-full h-full rounded rounded-2xl;
    min-height: 200px;
}

body {
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: large;
}
